.label {
  display: inline-block;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #272A36;

  padding-bottom: 4px;
}

.star {
  color: #E23834;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}
