.selectField {
  padding-right: 44px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.icon {
  right: 16px !important;
  width: 1em;
  height: 1em;

  &Open {
    transform: rotate(0);
  }
}

.popper {
  z-index: 9999;
}

.paper {
  background: white;
  width: 100%;
}

.item {
  height: 40px;
  display: flex !important;
  width: 100%;
  font-weight: 400;
  font-size: 14px !important;
  color: #272A36;
  padding: 12px 52px 12px 12px !important;
  background: white !important;
  border: 0;
  outline: none;
  text-align: left;
  transition: 0.2s all;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  position: relative;

  &__check {
    opacity: 0;
    position: absolute;
    //float: right;
    //margin-left: auto;
    right: 13px;
  }

  // enable hover only for desktops
  @media (hover: hover) {
    &:hover {
      background: #F7F7F7 !important;

      .item__check {
        transition: 0.2s all;
        opacity: 1;

        path {
          fill: #8E8E93;
        }
      }
    }
  }

  &[aria-selected="true"] {
    .item__check {
      display: inline-block;
      opacity: 1;

      path {
        fill: #4778EE !important;
      }
    }

    font-weight: 600;
  }
}

.menu {
  transform: translateY(2px) !important;
  box-shadow: none !important;
  border: 1px solid #4778EE;
  border-radius: 4px;
  background: white;

  &__list {
    padding: 0 !important;
  }
}

.value {
  color: #272A36;
  font-weight: 400;
  font-size: 14px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &__placeholder {
    font-weight: 400;
    font-size: 14px;
    color: #8E8E93;
  }
}

// NOTE: this fix for DatePicker, because time item located lower
// then DatePicker
.upperRootPopover {
  z-index: 1601 !important;
}
