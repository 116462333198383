.input input {
  font-size: 14px !important;
}

.openRangesHeader {
  display: flex;
  width: 100%;

  &__button {
    margin-right: 16px;

    background: white;
    outline: none;
    cursor: pointer;

    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #8E8E93;

    border: 1px solid #E5E6EE;
    border-radius: 4px;

    padding: 8px 12px;

    &Active {
      transition: 0.2s all;
      color: #FFFFFF;
      background-color: #4778EE;
    }
  }
}

@media(max-width: 700px) {
  .openRangesHeader {
    $gap: 16px;

    display: flex;
    flex-wrap: wrap;
    margin-bottom: calc(-1 * #{$gap} / 2);

    &__button {
      &:first-child {
        margin-bottom: 16px;
        width: 100%;
        //margin-right: 0;
      }

      &:not(:first-child) {
        width: calc(50% - $gap / 2);
      }

      &:first-child, &:last-child {
        margin-right: 0;
      }
    }
  }
}
