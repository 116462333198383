.root {
  z-index: 1600 !important;
}


.paper {
  background: #FFFFFF !important;
  box-shadow: 0px 187px 75px rgba(0, 0, 0, 0.01), 0px 105px 63px rgba(0, 0, 0, 0.05), 0px 47px 47px rgba(0, 0, 0, 0.09), 0px 12px 26px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
}

.content {
  padding: 24px;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
}

.label {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #8E8E93;
}

.selectedDates {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #272A36;
  flex-shrink: 0;
}

.footer {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.footerLabel {
  margin-top: 20px;
}

.timeRange {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  &__picker {
    width: 106px !important;
  }

  &__divider {
    margin-left: 8px;
    margin-right: 8px;
    height: 1px;
    width: 16px;
    background: #E2E2E2;
  }

  &__timezone {
    width: 165px;
    margin-left: 32px !important;
  }
}

.actions {
  margin-left: auto;
  display: inline-flex;
}

.cleanButton {
  border-radius: 8px !important;
  margin: 0 8px !important;

  &Hidden {
    visibility: hidden;
  }
}

@media(max-width: 700px) {
  .header {
    flex-direction: column;

    & > :not(:last-child) {
      margin-bottom: 8px;
    }
  }

  .timeRange {
    flex-wrap: wrap;

    &__divider {
      margin-left: 0 !important;
    }

    &__picker {
      width: auto !important;
      flex-grow: 1;

      &:first-child {
        margin-right: 8px;
      }
    }

    &__timezone {
      width: auto !important;
      margin-left: 0 !important;
      flex-grow: 1;
    }

    & > * {
      margin-bottom: 16px !important;
    }
  }

  .footer {
    margin-top: 12px !important;
    flex-direction: column;
  }

  .closeButton {
    flex-grow: 1;
  }

  .calendar__wrapper {
    display: flex;
    justify-content: center;
  }

  .content {
    padding: 16px !important;
  }
}
