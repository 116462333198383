body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

*, *::after, *::before {
    padding: 0;
    margin: 0;
    font-family: Roboto, arial, sans-serif;
    box-sizing: border-box;
}

a,
a:active,
a:hover,
a:visited {
    text-decoration: none;
}