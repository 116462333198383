.root {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  border: 0;
  background: transparent;
  cursor: pointer;
  transition: 0.2s background;

  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
}
