.container {
  touch-action: none  ;
}

.preview {
  height: 260px;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: move;
  position: relative;

  &Image {
    //max-height: 100%;
    //max-width: 100%;
    user-select: none;
    pointer-events: none;
    -webkit-user-drag: none;
  }

  &Frame {
    //$circle-radius: 90px;

    //width: ($circle-radius + 5) * 2;
    //height: ($circle-radius + 5) * 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 0 100vw rgba(0, 0, 0, 0.54);
    border: 5px solid white;

    &Circle {
      border-radius: 100%;
    }

    &Rectangle {
      border-radius: 20px;
    }
  }
}

.scale {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &Title {
    user-select: none;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #8E8E93;
    margin: 16px 0;
  }
}
