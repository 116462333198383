@import "@roolz/sdk/assets/mixins.scss";

.clear {
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s opacity;
  position: absolute;
  right: 10px;
  height: 100% !important;
  padding-right: 10px;
  transform: translateX(10px);

  &__show {
    visibility: visible !important;
    opacity: 1;
  }
}

.startAdornment {
  padding-left: 5px;
}

.checkbox {
  width: 24px;
  height: 24px;
  justify-content: center;

  & > * {
    margin: 0;
  }
}
