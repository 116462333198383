$avatar-size: 160px;

.wrapper {
  display: flex;
  min-height: 160px;
  align-items: center;
}

.imageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  min-width: $avatar-size;
  min-height: $avatar-size;
}

.backdrop {
  cursor: pointer;
  position: absolute;
  inset: 0;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: 0.2s;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: white;
  background-color: rgba(0, 0, 0, 0.64);
  opacity: 0;

  &.company {
    border-radius: 16%;
  }

  &:hover {
    opacity: 1;
  }
}

.avatarImage {
  width: $avatar-size;
  height: $avatar-size;
  border-radius: $avatar-size;
}

.content {
  margin-left: 24px;
}

.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #272A36;
  margin-bottom: 8px !important;
}

.description {
  font-weight: 400;
  font-size: 14px;
  color: #8E8E93;
  margin-bottom: 12px !important;
  line-height: 16px !important;
}

.actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 4px;
  }
}

.input {
  display: none;
}


@media(max-width: 370px) {
  .wrapper {
    flex-direction: column;
  }
  .content {
    margin-left: 0;
    margin-top: 12px;
    text-align: center;
  }
  .actions {
    justify-content: center;
  }
}
