@import '@roolz/sdk/assets/mixins.scss';
$text-dark: #272A36;

.option {
  &Icon {
    margin-right: 6px;
  }

  &Text {
    @include ellipsis;
  }

  &Name {
    display: flex;
    align-items: center;
    width: 100%;
    @include ellipsis;

    &Tag {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2px 4px 2px 4px;
      background: #F7F7F7;
      border-radius: 4px;
      margin-right: 11px;
    }
  }
}
