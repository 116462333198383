@import '@roolz/sdk/assets/mixins.scss';
$text-dark: #272A36;

.highlighted {
  & :global .MuiInputBase-root {
    background-color: #E8F0FC !important;
    & fieldset {
      border-color: #ABC1F7 !important;
    }
  }
}

.blackPlaceholder {
  & :global .MuiInputBase-root {
    & input {
      &::placeholder {
        color: $text-dark;
        opacity: 1;
      }
    }
  }
}

.option {
  $padding: 4px;

  width: 100%;
  display: flex;
  justify-content: space-between;

  &Icon {
    margin-right: 6px;
  }

  &Text {
    @include ellipsis;
  }

  &Name {
    display: flex;
    align-items: center;
    width: 100%;
    @include ellipsis;

    &Tag {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2px 4px 2px 4px;
      background: #F7F7F7;
      border-radius: 4px;
      margin-right: 11px;
    }

  }

  &__toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    cursor: pointer;
    &:hover path {
      fill: #4778EE;
    }
  }
}

.input {
  &Icon {
    margin-right: 4px;
  }
}

.clear {
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s opacity;
  position: absolute;
  right: 10px;
  height: 100% !important;
  padding-right: 10px;
  transform: translateX(10px);

  &__show {
    visibility: visible !important;
    opacity: 1;
  }
}

.popupIcon {
  display: inline-flex;
  top: 50% !important;
  right: 16px !important;
  transform: translateY(-50%) !important;
}

.popupIndicator {
  transform: rotate(0) !important;

  &:hover {
    background: none;
  }
}
