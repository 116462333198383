.root {
  height: 40px;
  position: relative;
  flex-shrink: 0;
}

.closeArea {
  width: 55px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  background: transparent;
  border: 0;
  outline: none;
  //border-radius: 5px;

  //&:focus {
  //  border: 1px solid rgba(1, 1, 1, 0.5);
  //}

  &>* {
    color: rgb(142, 142, 147);

    transform: rotate(0deg);
    transition: transform 0.3s !important;
  }

  &:hover>* {
    transform: rotate(180deg);
  }
}
