.aside {
  width: 100%;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  /**
    Extract z-index
   */
  z-index: 1000;


  &NoBackdrop {
    width: auto;
  }

  &WithShadow {
    .contentContainer {
      box-shadow: -436px 0px 174px rgba(0, 0, 0, 0.01), -245px 0px 147px rgba(0, 0, 0, 0.05), -109px 0px 109px rgba(0, 0, 0, 0.09), -27px 0px 60px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    }
  }
}

.backdrop {
  width: 100%;
  height: 100%;
  background: rgba(1, 1, 1, 0.57);
  //animation: backdrop-appear 0.3s;

  &NonInteractive {
    pointer-events: none;
  }

  &Transparent {
    background: transparent;
  }
}

.contentContainer {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  overflow-y: auto;

  &FullWidth {
    width: 100vw;
    width: 100svw;
  }

  &VisibleScroll {
    overflow-y: scroll;
  }
}

@keyframes :local(backdrop-appear) {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
