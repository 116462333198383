@import '@roolz/sdk/assets/mixins.scss';

.option {
  $padding: 4px;

  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: $padding !important;
  padding-bottom: $padding !important;
  //min-width: 0;

  &Icon {
    margin-right: 6px;
  }

  &Name {
    @include ellipsis;
  }
}

.input {
  &Icon {
    margin-right: 4px;
  }
}

.icon {
  pointer-events: none;
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: center;

  //position: absolute;
  //
  //top: 10px;
  //right: 10px;

  width: 28px;
  height: 28px;

  transition: 0.2s all;
  transition: 0.25s transform;

  &Body {
    transform: rotate(180deg);

    //padding: 5px;
  }

  &Open {
    transition: 0.3s all;

    transform: rotate(0deg);
  }

  &Clear {
    position: absolute;
    right: 15px;
    cursor: pointer;
  }
}
