$item-size: 38px;

.root {
  width: ($item-size) * 7;
}

.header {

}

.weekDay {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #8E8E93;
  width: $item-size;
  height: $item-size;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  user-select: none;
}

.days {
  display: flex;
  flex-wrap: wrap;

  &__item {
    width: $item-size;
    height: $item-size;

    background: transparent;
    user-select: none;
    border: 0;
    outline: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #272A36;
    border-radius: $item-size;
    cursor: pointer;
    position: relative;
    z-index: 2;

    &Today:after {
      content: '';
      background: #4778EE;
      width: 4px;
      height: 4px;
      border-radius: 100%;
      position: absolute;
      left: 50%;
      bottom: 20%;
      transform: translateX(-2px);
    }

    &:not(&Active):not(&:active):not(&ActivePossibleRange):not(&Unavailable):hover {
      color: #3667DD;

      &:before {
        content: '';
        border-radius: $item-size;
        border: 1px solid #3667DD;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 32px;
        height: 32px;
        z-index: -1;
      }
    }

    &:active, &Active {
      color: white;
      width: $item-size;

      &:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 32px;
        height: 32px;
        background: #4778EE;
        border-radius: 32px;
        z-index: -1;
      }
    }

    &Unavailable {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &InRange:before {
      border-radius: 0 !important;
      width: $item-size !important;
    }

    &ActiveFirst:before {
      border-radius: $item-size 0 0 $item-size !important;
      height: 32px;
      width: $item-size !important;
    }

    &ActiveLast:before {
      border-radius: 0 $item-size $item-size 0 !important;
      height: 32px !important;
      width: $item-size !important;
    }

    &ActivePossibleRange {
      color: white;

      &:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 32px;
        height: 32px;
        opacity: 0.4;
        background: #4778EE;
        border-radius: 32px;
        z-index: -1;
      }
    }
  }

  &__spacer {
    display: inline-block;
    width: $item-size;
    height: $item-size;
  }
}
