.container {
  width: 200px;
  max-width: 100%;
  position: relative;
  cursor: pointer;
  touch-action: none;
  display: inline-block;
}

.line {
  width: 100%;
  height: 2px;
  background: #E2E2E2;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.selectedLine {
  height: 2px;
  background: #4778EE;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.cursor {
  background: #4778EE;
  border-radius: 100%;
  position: relative;
}
