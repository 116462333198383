.root {
  display: flex;
}

.pane {
  &__container {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 30px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    &__month {
      padding: 8px 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #4778EE;
    }
  }
}

.backButton, .forwardButton {
  width: 30px;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  user-select: none;
  display: inline-flex;
  align-items: center;
}

.backButton {
  justify-content: flex-start;
}

.forwardButton {
  justify-content: flex-end;
}
