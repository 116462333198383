.root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s transform;
  transform: rotate(180deg);
  will-change: transform;

  &.expanded {
    transform: rotate(0);
  }
}
