@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin applyToChildrenExceptLast($property, $value) {
  & > *:not(:last-child) {
    #{$property}: #{$value}
  }
}

@mixin invisible-scroll {
  overflow: -moz-scrollbars-none;
  overflow: -moz-hidden-unscrollable;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
    width: 0 !important;
    -ms-overflow-style: none;
  }
}

@mixin placeholder-color($value) {
  &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: $value;
  }
  &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: $value;
    opacity:  1;
  }
  &::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: $value;
    opacity:  1;
  }
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $value;
  }
  &::-ms-input-placeholder { /* Microsoft Edge */
    color: $value;
  }

  &::placeholder { /* Most modern browsers support this now. */
    color: $value;
  }
}


@mixin pseudo-border($color) {
  position: relative;

  &:after {
    content: '';

    position: absolute;
    left: -1px;
    top: -1px;

    border: 1px solid $color;

    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border-radius: inherit;

    box-sizing: border-box;

    pointer-events: none;
  }
}
