//.warning {
//  background: #FEF9EB !important;
//  border: 1px solid #F9E8B5 !important;
//  color: #272A36 !important;
//  box-shadow: none !important;
//
//  border-radius: 8px !important;
//  min-width: auto !important;
//  padding: 6px 16px 6px 18px !important;
//
//  svg {
//    margin-right: 12px;
//  }
//}
//
//.success {
//  background: #EEFBF7 !important;
//  border: 1px solid #B4DCCF !important;
//  color: #272A36 !important;
//  box-shadow: none !important;
//
//  border-radius: 8px !important;
//  min-width: auto !important;
//  padding: 6px 16px 6px 18px !important;
//
//  svg {
//    margin-right: 12px;
//  }
//}
//
//.error {
//  background: #FBEEEE !important;
//  border: 1px solid #F2CACA !important;
//  color: #272A36 !important;
//  box-shadow: none !important;
//
//  border-radius: 8px !important;
//  min-width: auto !important;
//  padding: 6px 16px 6px 18px !important;
//
//  svg {
//    margin-right: 12px;
//  }
//}
//
//

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: auto !important;
  bottom: 0 !important;
  pointer-events: none;

  &>* {
    pointer-events: auto;
  }
}

.toast {
  --toastify-toast-min-height: auto !important;
  --toastify-toast-max-height: auto !important;
  --toastify-toast-width: calc(min(800px, 90vw)) !important;
  display: inline-block !important;

  background: #272A36 !important;

  border-radius: 8px !important;
  float: right;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.body {
  margin: 0 !important;
  padding: 0 !important;

  &>* {
    line-height: 0;
  }
}

@media(max-width: 800px) {
  .wrapper {
    //flex-wrap: wrap;
  }

  .actions {
    //width: 100%;
    justify-content: flex-end;
    margin-left: 0 !important;
  }

  .toast {
    max-width: 90vw;
    margin-bottom: 8px;
  }
}

.content {
  font-family: 'Roboto', arial, sans-serif;
  min-width: 1px;
}

.wrapper {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}

.title {
  //display: -webkit-box;
  //-webkit-line-clamp: 1;
  //-webkit-box-orient: vertical;
  //overflow: hidden;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 1px;

  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #FFFFFF;
  margin-top: 0;
  margin-bottom: 0;
}

.message {
  margin-top: 2px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.actions {
  flex-shrink: 0;
  margin-left: 24px;
  display: flex;

  &__button {
    padding: 8px 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    background: transparent;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}


.animate__animated {
  animation-duration: 0.3s;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
.animate__fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.animate__fadeOut {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-direction: reverse;
}
