$height: 35px;

.root {
  width: 100%;
  display: flex;
  align-items: center;
  height: $height;
  border: 0;
  outline: none;
  background: transparent;
  padding-left: 0;
  padding-right: 10px;
  transition: 0.3s all;
  cursor: pointer;

  &:hover {
    background: rgba(230, 230, 230, 0.6);
  }

  &.withAppend {
    padding-right: 0;
  }

  &.disabled {
    cursor: not-allowed;
    pointer-events: auto;

    .label {
      color: #8E8E93;
    }
  }

  .icon {
    width: $height;
    height: $height;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .label {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #272A36;
    text-decoration: none;
  }
}
