.wrapper {
  position: relative;
}

.content {
  //animation: content-hide 0.2s forwards reverse;

  &Hidden {
    animation: content-hide 0.3s forwards;
  }
}

.spinner {
  position: absolute;
  left: calc(50% - 45px);
  top: calc(50% - 7px);
  max-height: 100%;
  transform: scale(0.7);
}

//@keyframes content-hide {
//  0% {
//    opacity: 1;
//  }
//
//  100% {
//    opacity: 0;
//    visibility: hidden;
//  }
//}
