.avatar {
  --company-border-radius: 16%;

  border-radius: 100%;
  flex-shrink: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &Company {
    border-radius: var(--company-border-radius);
  }
}

.image {
  border-radius: 100%;
  width: 100%;
  height: 100%;

  //animation: 0.15s image-appear;

  &__company {
    border-radius: var(--company-border-radius) !important;
  }
}

.profilePlaceholder {
  width: 100%;
  height: 100%;
  border-radius: 100%;

  text-transform: uppercase;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  pointer-events: none;
  //opacity: 0;

  //animation: 0.1s 0.3s image-appear forwards;
}

.companyPlaceholder {
  width: 100%;
  height: 100%;
  border-radius: var(--company-border-radius);
  text-transform: uppercase;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  pointer-events: none;
  //opacity: 0;
  background: #EBECF2;
  //animation: 0.1s 0.3s image-appear forwards;
  &__img {
    width: 100%;
    height: auto;
    max-width: 50px;
  }
}

.online {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 8px;
    height: 8px;
    border: 1px solid white;
    background: #3EBF71;
    border-radius: 100%;
  }
}

.onlineRecently {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 8px;
    height: 8px;
    border: 1px solid white;
    background: #E59F42;
    border-radius: 100%;
  }
}


@keyframes image-appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
